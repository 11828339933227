import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import highlight1 from '../../assets/images/educational/main/highlight1.webp';
import highlight2 from '../../assets/images/educational/main/highlight2.webp';
import team from '../../assets/images/educational/main/team.webp';
import { useGtXs } from '../../hooks/useGtXs';
import BlackButton from '../BlackButton/BlackButton';
import Show from '../Show/Show';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import styles from './EducationalSectionView.module.scss';

export type SectionHighlight = {
    name: string
    src: any
    alt: string
    link: string
}

const SectionHighlights = () => {

    const history = useHistory()
    const gtXs = useGtXs()

    const max = 2
    const sectionHighlights = [
        {
            name: "D20 Coliseu",
            src: highlight1,
            alt: "D20 Coliseu",
            link: "/evento/20240927-d20-coliseu"
        },
        {
            name: "Programa Escolas",
            src: highlight2,
            alt: "Programa Escolas",
            link: "/educativo/programa-escolas"
        },

    ]

    const handleClick = (sectionHighlight: SectionHighlight) => {
        const url = sectionHighlight.link
        if (url.startsWith("http")) {
            window.location.href = url
        } else {
            history.push(url)
        }
    }

    return (
        <>
            <Show on={gtXs}>
                <Grid item xs={12} sm={5} container direction="column" justify="space-between" className={styles.Highlights}>
                    <p className={styles.Name}>
                        Destaques
                    </p>

                    {sectionHighlights.slice(0, max).map(sectionHighlight => (
                        <div className={styles.SectionHighlight} onClick={() => handleClick(sectionHighlight)}>
                            <img src={sectionHighlight.src} alt={sectionHighlight.alt} />
                        </div>
                    ))}

                </Grid>
            </Show>
            <Show on={!gtXs}>
                <div className={styles.Highlights}>
                    <p className={styles.Name}>Destaques</p>
                    <Grid container direction="row" justify="space-between">
                        {sectionHighlights.slice(0, max).map(sectionHighlight => (
                            <Grid item xs={6} className={styles.SectionHighlight} onClick={() => handleClick(sectionHighlight)}>
                                <div>
                                    <img src={sectionHighlight.src} alt={sectionHighlight.alt} />
                                    <p>{sectionHighlight.name}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Show>
        </>
    )
}

const getVideoOpts = (gtXs: boolean) => {
    const w = gtXs ? 700 : (window.innerWidth) - 30;
    const h = w * 0.632
    return { width: `${w}px`, height: `${h}px` }
}

export default function EducationalSectionView() {

    const gtXs = useGtXs()
    const spacing = gtXs ? 8 : 0;

    const videoUrl = "https://www.youtube.com/watch?v=hbOAVdG70aI"
    const videoOpts = getVideoOpts(gtXs)

    return (
        <div>
            <div className={styles.SectionBanner} />

            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    <div>

                        <p>Pela primeira vez em 81 anos de história o Coliseu lança, em 2023, o seu Serviço Educativo, para reforçar a ligação de crianças, jovens e de todos os públicos às artes.</p>

                        <p>Situado no coração da cidade do Porto desde 1941, o Coliseu orgulha-se de ser uma das salas de espetáculo mais democráticas do país, chamando a si uma multiplicidade de parceiros, instituições, escolas e associações educativas. Pretende agora criar, com todos, laços vincados e duradouros, partilhar o sentido da sua missão e proporcionar lugar e tempo para a educação e fruição artísticas.</p>

                    </div>

                    <div className={styles.Newsletter}>
                        <BlackButton text="Subscrever Newsletter" to="/newsletter/educativo" />
                    </div>

                    <Grid container spacing={spacing}>
                        <Grid item xs={12} sm={7} className={styles.VideoPlayerWrapper}>
                            <VideoPlayer url={videoUrl} opts={videoOpts} />
                        </Grid>
                        <SectionHighlights />
                    </Grid>

                    <Grid container className={styles.Team} spacing={spacing}>
                        <Grid item>
                            <img src={team} alt="equipa serviço educativo" />
                        </Grid>

                        <Grid item className={styles.Names}>
                            <br />
                            <br />
                            <p><span>DIREÇÃO E COORDENAÇÃO PEDAGÓGICA</span></p>
                            <p>Filipa Godinho</p>
                            <br />
                            <p><span>GESTÃO E PRODUÇÃO</span></p>
                            <p>Tiago Azevedo</p>
                            <br />
                            <p><span style={{ letterSpacing: 1 }}>COLABORADORES</span></p>
                            <p>Projecto ELO: Pedro Lamares, António Serginho, Eliana Campos, Beatriz Bizarro, Carina Constantino, Carolina Rocha</p>
                            <br />
                            <p>Programa Escolar: Beatriz Bizarro, Carina Constantino, Diana Fernandes, Mariana Dorigatti, Luís Martins</p>

                        </Grid>
                    </Grid>

                </main>
            </Grid>
        </div>
    )
}
